import React from "react";
const PageNotFound = () => {
  return (
    <>
      <div className="spinner-class">
        <h1>404, Page not found...</h1>
      </div>
    </>
  );
};

export default PageNotFound;
